import React from "react"
import Layout from "../shared/layout/Layout"
import Blog from "../blog/Blog"

const BlogPage = () => (
  <Layout>
    <Blog />
  </Layout>
)

export default BlogPage
