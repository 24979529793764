import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { PRIMARY_GREEN, WHITE } from "../../shared/Colors"

const BlogPostPreview = ({ hit }) => {
  return (
    <BlogPostPreviewContainer>
      <PostLink to={`/blog/${hit.slug}`}>
        <h1>{hit.title}</h1>
        <h5>
          &rarr; {hit.date.split("T")[0]}{" "}
          {hit.todayILearned ? "(Today I Learned)" : ""}
        </h5>
        <p>{hit.excerpt}</p>
      </PostLink>
    </BlogPostPreviewContainer>
  )
}

const BlogPostPreviewContainer = styled.div`
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 2px solid ${PRIMARY_GREEN}50;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`

const PostLink = styled(Link)`
  text-decoration: none;
  color: ${WHITE};

  > * {
    padding-left: 0rem;
    transition-duration: 0.4s;
  }

  &:hover {
    > * {
      border-left: 5px solid ${PRIMARY_GREEN};
      padding-left: 1rem;
      transition-duration: 0.4s;
    }
  }
`

export default BlogPostPreview
