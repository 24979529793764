import React from "react"
import styled from "@emotion/styled"
import { Container } from "@material-ui/core"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  Hits,
  connectStateResults,
} from "react-instantsearch-dom"
import BlogPostPreview from "./components/BlogPostPreview"
import { GREY, WHITE } from "../shared/Colors"
import NoSearchResultsFound from "./components/NoSearchResultsFound"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)

console.log(process.env.GATSBY_ALGOLIA_INDEX_NAME)

const Blog = React.memo(() => {
  const Results = connectStateResults(
    ({ searchState, searchResults, children }) =>
      searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : (
        <NoSearchResultsFound query={searchState.query} />
      )
  )

  return (
    <div className={"secondary-segment"}>
      <PageSegment>
        <InstantSearch
          searchClient={searchClient}
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        >
          <BlogSearchBox />
          <Results>
            <StyledHits hitComponent={BlogPostPreview} />
          </Results>
        </InstantSearch>
      </PageSegment>
    </div>
  )
})

// STYLES
const PageSegment = styled(Container)`
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 120ch;

  &:last-of-type {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 0.5rem;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0.4rem;
  }
  h5 {
    margin-top: 0;
    font-family: monospace;
    font-size: 1rem;
  }
`

const BlogSearchBox = styled(SearchBox)`
  input.ais-SearchBox-input {
    padding-left: 1rem;
    padding-top: 0.5rem;
    height: 2.5rem;
    width: 100%;
    border-radius: 7px;
    background-color: ${GREY}20;
    color: ${WHITE};
    outline: none;
    font-family: "Baloo Tamma 2", cursive;
    font-size: 1.5rem;
    ::placeholder {
      color: ${GREY}50;
    }
  }
  button.ais-SearchBox-submit {
    display: none;
  }
  button.ais-SearchBox-reset {
    display: none;
  }
`

const StyledHits = styled(Hits)`
  ul {
    list-style: none;
  }
`

export default Blog
